<template>
  <div class="sms-dialog">
    <el-dialog
      :title="title"
      :visible.sync="dialogStatus"
      width="630px"
      custom-class="set-sms"
    >
      <div class="content">
        <el-form ref="smsform" :model="formData" label-width="150px">
          <el-form-item label="请选择短信通知类型:" prop="telephone">
            <el-checkbox-group v-model="noticeList">
              <el-checkbox label="1"
                >资金消息（接收资金变动消息信息）</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="通知用户手机号码:" prop="telephone">
            {{ formData.telephone || "" }}
          </el-form-item>
        </el-form>
        <div class="btn-wrap">
          <el-button @click="handleClose">取消</el-button>
          <el-button @click="submit" class="hy-btn" :loading="submiting"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { financeChangeNotice } from "@/api/hy";
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  props: {
    isShow: {
      type: Boolean,
      default: () => false,
    },
    smsDialogData: {
      type: Object,
      default: () => {},
    },
    noticeObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      submiting: false,
      title: "设置短信通知",
      formData: {
        checked: true,
        telephone: "",
      },
      noticeList: [],
    };
  },
  watch: {
    isShow() {
      this.$nextTick(() => {
        this.formData.telephone = this.smsDialogData?.telephone || "";
        this.noticeList = this.noticeObj?.havePer
          ? [this.noticeObj.noticeType]
          : [];
      });
    },
  },
  computed: {
    dialogStatus: {
      get() {
        return this.isShow;
      },
      set(val) {
        this.$emit("update:isShow", val);
      },
    },
  },
  methods: {
    handleClose() {
      this.dialogStatus = false;
    },
    submit() {
      this.submiting = true;
      let data = {
        enterpriseId: Vue.ls.get(types.enterpriseId),
        noticeType: this.noticeList.join(","),
        telephone: this.smsDialogData.telephone,
        userId: this.smsDialogData.userId,
      };
      financeChangeNotice(data)
        .then((res) => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
        })
        .finally(() => {
          this.$emit("changeOver");
          this.submiting = false;
          this.dialogStatus = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-wrap {
  text-align: right;
}
::v-deep {
  .set-sms {
    border-radius: 10px !important;
  }
  .el-dialog__header {
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px 10px 0 0 !important;
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .el-form {
    width: 500px;
  }
  .hy-btn {
    background: linear-gradient(90deg, #2079fb, #4cb2fc);
    color: white;
  }
}
</style>
